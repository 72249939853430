/*
===============================================================================
  Webpack imports for css, img and fonts
===============================================================================
*/
// Stylesheets
import '../scss/app.scss';

/*
===============================================================================
  Application imports
===============================================================================
*/

import { WINDOW, DOCUMENT, BODY } from './helpers/browser';

async function bootstrap() {
  if (Array.from(BODY.querySelectorAll('.js-nav')).length) {
    const { default: Nav } = await import(
      /* webpackChunkName: "modules-Nav" */ './modules/Nav'
    );
    Array.from(BODY.querySelectorAll('.js-nav')).forEach((el) => Nav(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-utm-button')).length) {
    const { default: UtmButton } = await import(
      /* webpackChunkName: "modules-UtmButton" */ './modules/UtmButton'
    );
    Array.from(BODY.querySelectorAll('.js-utm-button')).forEach((el) =>
      UtmButton(el)
    );
  }

  if (Array.from(BODY.querySelectorAll('.js-plyr')).length) {
    const { default: Plyr } = await import(
      /* webpackChunkName: "modules-Plyr" */ './modules/Plyr'
    );
    Array.from(BODY.querySelectorAll('.js-plyr')).forEach((el) => Plyr(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-form')).length) {
    const { default: Form } = await import(
      /* webpackChunkName: "modules-Form" */ './modules/Form'
    );
    Array.from(BODY.querySelectorAll('.js-form')).forEach((el) => Form(el));
  }

  if (Array.from(BODY.querySelectorAll('.js-load-more')).length) {
    const { default: LoadMore } = await import(
      /* webpackChunkName: "modules-LoadMore" */ './modules/LoadMore'
    );
    Array.from(BODY.querySelectorAll('.js-load-more')).forEach((el) =>
      LoadMore(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-accordion')).length) {
    const { default: Accordion } = await import(
      /* webpackChunkName: "modules-Accordion" */ './modules/Accordion'
    );
    Array.from(BODY.querySelectorAll('.js-accordion')).forEach((el) =>
      Accordion(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-hide-show-button')).length) {
    const { default: HideShowButton } = await import(
      /* webpackChunkName: "modules-HideShowButton" */ './modules/HideShowButton'
    );
    Array.from(BODY.querySelectorAll('.js-hide-show-button')).forEach((el) =>
      HideShowButton(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-scroll-to-form')).length) {
    const { default: ScrollToForm } = await import(
      /* webpackChunkName: "modules-ScrollToForm" */ './modules/ScrollToForm'
    );
    Array.from(BODY.querySelectorAll('.js-scroll-to-form')).forEach((el) =>
      ScrollToForm(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-unsubscribe-form')).length) {
    const { default: UnsubscribeForm } = await import(
      /* webpackChunkName: "modules-UnsubscribeForm" */ './modules/UnsubscribeForm'
    );
    const {
      AUTH_API_KEY,
      PROD_AUTH_API_KEY,
      AUTH_API_ENDPOINT,
      PROD_AUTH_API_ENDPOINT,
      MAILCHIMP_API_ENDPOINT,
      PROD_MAILCHIMP_API_ENDPOINT,
      MANDRILLAPP_API_KEY,
      MANDRILLAPP_API_ENDPOINT,
      MAILCHIMP_API_LEAD_NURTURE_ENDPOINT,
      PROD_MAILCHIMP_API_LEAD_NURTURE_ENDPOINT,
      SEND_ERROR_EMAIL_ENDPOINT,
      PROD_SEND_ERROR_EMAIL_ENDPOINT
    } = await import('./helpers/constants.js');
    Array.from(BODY.querySelectorAll('.js-unsubscribe-form')).forEach((el) =>
      UnsubscribeForm(
        el,
        AUTH_API_KEY,
        PROD_AUTH_API_KEY,
        AUTH_API_ENDPOINT,
        PROD_AUTH_API_ENDPOINT,
        MAILCHIMP_API_ENDPOINT,
        PROD_MAILCHIMP_API_ENDPOINT,
        MANDRILLAPP_API_KEY,
        MANDRILLAPP_API_ENDPOINT,
        MAILCHIMP_API_LEAD_NURTURE_ENDPOINT,
        PROD_MAILCHIMP_API_LEAD_NURTURE_ENDPOINT,
        SEND_ERROR_EMAIL_ENDPOINT,
        PROD_SEND_ERROR_EMAIL_ENDPOINT
      )
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-nz-form')).length) {
    const { default: NZForm } = await import(
      /* webpackChunkName: "modules-NZForm" */ './modules/NZForm'
    );
    Array.from(BODY.querySelectorAll('.js-nz-form')).forEach((el) =>
      NZForm(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.custom-header')).length) {
    const { default: HideGlobalHeader } = await import(
      /* webpackChunkName: "modules-HideGlobalHeader" */ './modules/HideGlobalHeader'
    );
    Array.from(BODY.querySelectorAll('.custom-header')).forEach((el) =>
      HideGlobalHeader(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.custom-footer')).length) {
    const { default: HideGlobalFooter } = await import(
      /* webpackChunkName: "modules-HideGlobalFooter" */ './modules/HideGlobalFooter'
    );
    Array.from(BODY.querySelectorAll('.custom-footer')).forEach((el) =>
      HideGlobalFooter(el)
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-unsubscribe-form-nz')).length) {
    const { default: UnsubscribeFormNZ } = await import(
      /* webpackChunkName: "modules-UnsubscribeForm" */ './modules/UnsubscribeFormNZ'
    );
    const {
      AUTH_API_KEY,
      PROD_AUTH_API_KEY,
      AUTH_API_ENDPOINT,
      PROD_AUTH_API_ENDPOINT,
      MAILCHIMP_API_ENDPOINT,
      PROD_MAILCHIMP_API_ENDPOINT,
    } = await import('./helpers/constants.js');
    Array.from(BODY.querySelectorAll('.js-unsubscribe-form-nz')).forEach((el) =>
      UnsubscribeFormNZ(
        el,
        AUTH_API_KEY,
        PROD_AUTH_API_KEY,
        AUTH_API_ENDPOINT,
        PROD_AUTH_API_ENDPOINT,
        MAILCHIMP_API_ENDPOINT,
        PROD_MAILCHIMP_API_ENDPOINT,
      )
    );
  }
  if (Array.from(BODY.querySelectorAll('.js-vwb-form')).length) {
    const { default: VWBForm } = await import(
      /* webpackChunkName: "modules-VWBForm" */ './modules/VWBForm'
    );
    const {
      carMakeList
    } = await import('./helpers/constants.js');
    Array.from(BODY.querySelectorAll('.js-vwb-form')).forEach((el) =>
      VWBForm(el, carMakeList)
    );
  }
}

((window, document) => {
  if (document.readyState === 'complete' || document.readyState !== 'loading') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
})(WINDOW, DOCUMENT);
